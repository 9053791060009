export function useIntersectionAnimation() {
  const elementRef = ref<HTMLElement | null>(null)

  onMounted(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible')
            
            const fadeElements = entry.target.querySelectorAll('.fade-up')
            fadeElements.forEach((el, index) => {
              setTimeout(() => {
                el.classList.add('visible')
              }, index * 100)
            })

            const popElements = entry.target.querySelectorAll('.pop-in')
            popElements.forEach((el) => {
              el.classList.remove('pop-in')
              void (el as HTMLElement).offsetWidth
              el.classList.add('pop-in')
            })
          }
        })
      },
      { threshold: 0.4 }
    )

    if (elementRef.value) {
      observer.observe(elementRef.value)
    }

    return () => {
      if (elementRef.value) {
        observer.unobserve(elementRef.value)
      }
    }
  })

  return {
    elementRef
  }
} 