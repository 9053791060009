<script setup lang="ts">
import CTAFeature from '@/components/marketing/CTAFeature.vue'
import { useIntersectionAnimation } from '@/composables/use-intersection-animation';
import type { HomePageCTA } from "@/types/marketing";

interface Props {
  content: HomePageCTA;
}

defineProps<Props>();

const { elementRef } = useIntersectionAnimation();
</script>

<template>
  <section class="relative overflow-hidden">
    <div 
      ref="elementRef"
      class="relative w-full py-32 lg:py-40 overflow-x-hidden" 
      id="download"
    >
      <div class="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center mb-20 flex flex-col items-center fade-up">
          <div class="flex items-center px-4 py-2 rounded-full bg-white/80 shadow-md mb-4">
            <span class="bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary  font-semibold text-sm">{{ content.subtitle }}</span>
          </div>
          <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold tracking-tight text-dark-gray mb-5 relative">
            {{ content.title }}
            <span class="absolute -bottom-2 left-0 right-0 h-1 bg-gradient-to-r from-transparent via-primary-light/40 to-transparent"></span>
          </h2>
          <p class="text-dark-gray max-w-3xl mx-auto mb-10">
            {{ content.description }}
          </p>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <!-- Employers Card -->
          <div class="group relative overflow-hidden bg-white rounded-2xl shadow-xl transform transition-all duration-500 hover:shadow-2xl card-3d border border-primary hover:-translate-y-1 fade-up">
            <div class="relative z-10 p-8 sm:p-10 h-full flex flex-col card-content">
              <div class="mb-8">
                <div class="relative h-16 w-16 rounded-full flex items-center justify-center shadow-lg overflow-hidden bg-primary">
                  <div class="absolute inset-0 rounded-full border-2 border-dashed border-white/30 spin-slow"></div>
                  <Icon :name="content.employers.icon" class="h-8 w-8 text-white relative z-10" />
                </div>
              </div>
              <h2 class="text-2xl sm:text-3xl font-bold tracking-tight text-primary mb-3 card-title relative">
                {{ content.employers.title }}
                <span class="absolute bottom-0 left-0 h-px w-0 bg-primary/50 group-hover:w-1/2 transition-all duration-1000 ease-out"></span>
              </h2>

              <ul class="space-y-5 mb-8 flex-grow">
                <CTAFeature v-for="feature in content.employers.features" :key="feature.title" v-bind="feature" />
              </ul>

              <div class="mt-auto relative z-20">
                <PrimaryButton
                  @click="navigateTo(content.employers.cta.route)"
                  large
                  class="w-full justify-center bg-primary text-white hover:bg-primary-dark transition-all duration-300 relative overflow-hidden group"
                >
                  <div class="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000"></div>
                  <span class="relative z-10 flex items-center">
                    <span class="mr-1">{{ content.employers.cta.text }}</span>
                    <Icon
                      :name="content.employers.cta.icon"
                      class="ml-1 h-5 w-5 transform transition-transform duration-300 group-hover:translate-x-1"
                    />
                  </span>
                </PrimaryButton>
              </div>
            </div>
          </div>

          <div class="group relative overflow-hidden bg-white rounded-2xl shadow-xl transform transition-all duration-500 hover:shadow-2xl card-3d border border-primary hover:-translate-y-1 fade-up">
            <div class="relative z-10 p-8 sm:p-10 h-full flex flex-col card-content">
              <div class="mb-8">
                <div class="relative h-16 w-16 rounded-full flex items-center justify-center shadow-lg overflow-hidden bg-primary">
                  <div class="absolute inset-0 rounded-full border-2 border-dashed border-white/30 spin-slow"></div>
                  <Icon :name="content.candidates.icon" class="h-8 w-8 text-white relative z-10" />
                </div>
              </div>

              <h2 class="text-2xl sm:text-3xl font-bold tracking-tight text-primary mb-3 card-title relative">
                {{ content.candidates.title }}
                <span class="absolute bottom-0 left-0 h-px w-0 bg-primary/50 group-hover:w-1/2 transition-all duration-1000 ease-out"></span>
              </h2>

              <ul class="space-y-5 mb-8 flex-grow">
                <CTAFeature v-for="feature in content.candidates.features" :key="feature.title" v-bind="feature" />
              </ul>

              <div class="mt-auto relative z-20">
                <PrimaryButton
                  @click="navigateTo(content.candidates.cta.route)"
                  large
                  class="w-full justify-center bg-primary text-white hover:bg-primary-dark transition-all duration-300 relative overflow-hidden group"
                >
                  <div class="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000"></div>
                  <span class="relative z-10 flex items-center">
                    <span class="mr-1">{{ content.candidates.cta.text }}</span>
                    <Icon
                      :name="content.candidates.cta.icon"
                      class="ml-1 h-5 w-5 transform transition-transform duration-300 group-hover:translate-x-1"
                    />
                  </span>
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
